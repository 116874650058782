import React, { Component } from 'react';

export default class Housing extends Component {
    render() {
        return (
            <div>
                <h1>Housing</h1>
            </div>
        );
    }
}