import React from 'react';

// Components
import NavBar from './Components/nav_bar';

// Pages
import Events from './pages/Events';
import Housing from './pages/Housing';
import Things_to_do from './pages/Things_to_do';
import Home from './pages/Home';

function App() {
  let page
  switch(window.location.pathname){
    case "/":
      page = <Home/>
      break;
    case "/housing":
      page = <Housing/>
      break;
    case "/events":
      page = <Events/>
      break;
    case "/things-to-do":
      page = <Things_to_do/>
      break;
  }
  return (
    <>
      <NavBar/>
      {page}
    </>
  );
}

export default App;