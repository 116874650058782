import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useResolvedPath, useMatch } from 'react-router';

export default class NavBar extends Component {
    path: string = window.location.pathname;
    
    render() {
        return (
            <nav className='nav'>
                <a className='site-title' href='/'>Home</a>
                <ul>
                    <li>
                        <a href="/housing">Housing</a>
                    </li>
                    <li>
                        <a href="/events">Events</a>
                    </li>
                    <li>
                        <a href="/things-to-do">Things to do</a>
                    </li>

                    {/* <CustomLink to="/housing">Housing</CustomLink>
                    <CustomLink to="/events">Events</CustomLink>
                    <CustomLink to="/things-to-do">Things to do</CustomLink> */}
                    <li>
                        <a href="/">RSVP</a>
                    </li>
                    <li>
                        <a href="/">Registry</a>
                    </li>
                </ul>
            </nav>
            
        );
    }
}

// interface CustomLinkProps {
//   to: string;
//   children: React.ReactNode;
//   [key: string]: any;
// }

// function CustomLink({ to, children, ...props }: CustomLinkProps) {
//   const resolvedPath = useResolvedPath(to);
//   const isActive = useMatch({ path: resolvedPath.pathname, end: true });

//   return (
//     <li className={isActive ? "active" : ""}>
//       <Link to={to} {...props}>
//         {children}
//       </Link>
//     </li>
//   );
// }