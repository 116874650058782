import React, { Component } from 'react';

export default class Things_to_do extends Component {
    render() {
        return (
            <div>
                <h1>Things to do</h1>
            </div>
        );
    }
}